import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import campaign from './slices/Campaignslice';
import patient from './slices/PatientSlice';
import outcomeSlice from 'store/slices/outcome-slice';

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        campaign,
        patient,
        outcomeSlice,
        ...asyncReducers,
    });
    return combinedReducer(state, action);
};

export default rootReducer;
