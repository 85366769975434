import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';

import { AUTH_TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';

const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000,
});

// Config
const TOKEN_PAYLOAD_KEY = 'Authorization';

// API Request interceptor
service.interceptors.request.use(
    (config) => {
        const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

        if (jwtToken) {
            config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
        }

        console.log('Request Headers:', config.headers);

        return config;
    },
    (error) => {
        // Do something with request error here
        notification.error({
            message: 'Error',
        });
        Promise.reject(error);
    }
);

// API respone interceptor
service.interceptors.response.use(
    (response) => {
        console.log('response in interceptor is ------> ', response);
        return response.data;
    },
    (error) => {
        console.log('Error   =========>', error.response);
        let notificationParam = {
            message: error.response.message,
        };

        if (error?.response?.status) {
            notificationParam.message = error.response.data.message;
        }
        notification.error(notificationParam);

        return Promise.reject(error);
    }
);

export default service;
