import React from 'react';
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig';

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() =>
            import('views/auth-views/authentication/login')
        ),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() =>
            import('views/auth-views/authentication/register')
        ),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() =>
            import('views/auth-views/authentication/forgot-password')
        ),
    },
    {
        key: 'form',
        path: `${APP_PREFIX_PATH}/form/:_id`,
        component: React.lazy(() => import('views/app-views/boarding-form')),
    },
    {
        key: 'successPage',
        path: `${APP_PREFIX_PATH}/successPage`,
        component: React.lazy(() =>
            import('views/app-views/success-page/index')
        ),
    },
];

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() =>
            import('views/app-views/dashboards/default')
        ),
    },
    {
        key: 'patients',
        path: `${APP_PREFIX_PATH}/patients`,
        component: React.lazy(() => import('views/app-views/patients/index')),
    },
    {
        key: 'campaigns',
        path: `${APP_PREFIX_PATH}/campaigns`,
        component: React.lazy(() => import('views/app-views/campaigns/index')),
    },
    {
        key: 'outcomes',
        path: `${APP_PREFIX_PATH}/outcomes`,
        component: React.lazy(() => import('views/app-views/outcomes/index')),
    },
    {
        key: 'outcomesDetail',
        path: `${APP_PREFIX_PATH}/outcomes/components/outcomesDetail/:id?`,
        component: React.lazy(() =>
            import('views/app-views/outcomes/components/outcomesDetail')
        ),
    },
    {
        key: 'outcomesResponseList',
        path: `${APP_PREFIX_PATH}/outcomes/components/responseList`,
        component: React.lazy(() =>
            import('views/app-views/outcomes/components/responseList')
        ),
    },
    {
        key: 'outcomesResponseDetail',
        path: `${APP_PREFIX_PATH}/outcomes/components/responseDetail`,
        component: React.lazy(() =>
            import('views/app-views/outcomes/components/responseDetail')
        ),
    },

    {
        key: 'manager-user',
        path: `${APP_PREFIX_PATH}/manager-user`,
        component: React.lazy(() =>
            import('views/app-views/manager-user/index')
        ),
    },
    {
        key: 'counsellor',
        path: `${APP_PREFIX_PATH}/counsellor`,
        component: React.lazy(() => import('views/app-views/counsellor/index')),
    },
    {
        key: 'counsellorDetail',
        path: `${APP_PREFIX_PATH}/counsellor/components/counsellorDetail/:id?`,
        component: React.lazy(() =>
            import('views/app-views/counsellor/components/counsellorDetail')
        ),
    },
    {
        key: 'newCounsellor',
        path: `${APP_PREFIX_PATH}/counsellor/components/NewCounsellor`,
        component: React.lazy(() =>
            import('views/app-views/counsellor/components/NewCounsellor')
        ),
    },
    {
        key: 'roleAssign',
        path: `${APP_PREFIX_PATH}/manager-users/components/assignRole`,
        component: React.lazy(() =>
            import('views/app-views/manager-user/components/assign-role')
        ),
    },
    {
        key: 'qr-code',
        path: `${APP_PREFIX_PATH}/qr-code`,
        component: React.lazy(() => import('views/app-views/qr-code/index')),
    },
    {
        key: 'newQRCode',
        path: `${APP_PREFIX_PATH}/qr-code/components/newQRCode`,
        component: React.lazy(() =>
            import('views/app-views/qr-code/components/newQRCode')
        ),
    },
    {
        key: 'generateQRCode',
        path: `${APP_PREFIX_PATH}/qr-code/components/generateQRCode`,
        component: React.lazy(() =>
            import('views/app-views/qr-code/components/generateQRCode')
        ),
    },
    {
        key: 'patientDetail',
        path: `${APP_PREFIX_PATH}/patients/components/patientDetail/:id?`,
        component: React.lazy(() =>
            import('views/app-views/patients/components/patientDetail')
        ),
    },
    {
        key: 'CampaignsDetail',
        path: `${APP_PREFIX_PATH}/campaigns/components/campaignDetail`,
        component: React.lazy(() =>
            import('views/app-views/campaigns/components/campaignDetail')
        ),
    },
    {
        key: 'ResponseList',
        path: `${APP_PREFIX_PATH}/campaigns/components/responseList`,
        component: React.lazy(() =>
            import('views/app-views/campaigns/components/responseList')
        ),
    },
    {
        key: 'ResponseDetail',
        path: `${APP_PREFIX_PATH}/campaigns/components/responseDetail`,
        component: React.lazy(() =>
            import('views/app-views/campaigns/components/responseDetail')
        ),
    },
    {
        key: 'addNewPatient',
        path: `${APP_PREFIX_PATH}/patients/components/addNewPatient`,
        component: React.lazy(() =>
            import('views/app-views/patients/components/addNewPatient')
        ),
    },

    {
        key: 'campaignInfo',
        path: `${APP_PREFIX_PATH}/campaign/campaignInfo`,
        component: React.lazy(() =>
            import('views/app-views/campaigns/components/Campaigninfo')
        ),
    },
];
