import { createAsyncThunk } from '@reduxjs/toolkit';
import fetch from 'auth/FetchInterceptor';
import Alertify from 'utils/alertify';

export function ManipulateCreateOutcomeDetails(originalObject) {
    // Destructure the original object to extract the parts we need to transform
    const {
        outcomeSurveyQnA,
        profilingSurveyQnA,
        campaignDetails,
        outcomeName,
    } = originalObject;

    // Transform the outcomeSurveyQnA array
    const transformedOutcomeSurveyQnA = outcomeSurveyQnA.map((item) => ({
        question: item.questions,
        // answerOptions: item.options.map((option) => option.value),
        answerOptions: item.options,
    }));

    // Transform the profilingSurveyQnA array
    const transformedProfilingSurveyQnA = profilingSurveyQnA.map((item) => ({
        question: item.questions,
        // answerOptions: item.options.map((option) => option.value),
        answerOptions: item.options,
    }));

    // Construct the new object with the transformed structure
    const transformedObject = {
        campaignId: campaignDetails.value, // Assuming you want to keep the original campaignId
        outcomeName: outcomeName, // Assuming you want to keep the original outcomeName
        campaignName: campaignDetails.label, // Assuming you want to use the label as the campaignName
        profilingSurveyQnA: transformedProfilingSurveyQnA,
        outcomeSurveyQnA: transformedOutcomeSurveyQnA,
    };

    return transformedObject;
}

export const CreateOutcome = createAsyncThunk(
    'CreateOutcome',
    async ({ payload }, { rejectWithValue }) => {
        try {
            console.log(payload);
            const { data } = await fetch({
                url: `/survey/create`,
                method: 'post',
                data: { ...payload },
            });
            Alertify.success('Successfully created the outcome survey');
            return { data };
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const UpdateOutcome = createAsyncThunk(
    'UpdateOutcome',
    async ({ payload, id }, { rejectWithValue }) => {
        try {
            console.log(payload);
            const { data } = await fetch({
                url: `/survey/${id}`,
                method: 'put',
                data: { ...payload },
            });
            Alertify.success('Successfully created the outcome survey');
            return { data };
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const GetAllOutcomeListings = createAsyncThunk(
    'GetAllOutcomeListings',
    async ({ page = 1, pageSize = 10, search = '' }, { rejectWithValue }) => {
        try {
            const { data, totalItems } = await fetch({
                url: `/survey/getAll?pageNumber=${page}&search=${search}&pageSize=${pageSize}`,
                method: 'get',
                data: { page, pageSize, search },
            });
            return { data, totalItems };
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);

export const GetOutcomesDetails = createAsyncThunk(
    'GetOutcomesDetails',
    async ({ outcomeId }, { rejectWithValue }) => {
        try {
            const { data } = await fetch({
                url: `/survey/${outcomeId}`,
                method: 'get',
            });
            return { data };
        } catch (err) {
            return rejectWithValue(err.response?.data?.message || 'Error');
        }
    }
);
